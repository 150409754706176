import React, { useState } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "Do we have to pay extra for Whatsapp?",
      answer: `No, there’s no extra charges for whatsapp messages. We use Whatsapp Web. We understand how important it is to stay connected with your clients for sending invoices, appointment reminders, and promotional messages. You can purchase Whatsapp credits for premium package when you want to automate the whatsapp messages.`,
    },
    {
      question: "The price of salonboost is very competative, will you increase the price?",
      answer: `We will not increase the price for our existing clients, the price will remain same for whatever price you have purchased for 3 years.`,
    },
    {
      question: "Will be there any additional charges if I have more than 10 employees?",
      answer: "No, Our plans are for unlimited employees and unlimited clients.",
    },
    {
      question: "Can I switch my subscription plan?",
      answer: `Absolutely! You can upgrade or downgrade your subscription anytime. Just contact our support team, and we’ll help you with the process.`,
    },
    {
      question: "How secure is my information?",
      answer: `Your data security is our top priority. You can select your preferred data center from the beginning. Our servers are located in state-of-the-art data centers with biometric security and around-the-clock monitoring. We also keep our application updated with the latest security features, including SSL encryption at no extra charge.`,
    },
    {
      question: "Are there fees if I cancel my subscription?",
      answer: `No, there are no cancellation fees with our pay-as-you-go model. You can cancel whenever you like, and your cancellation will take effect at the end of your current term.`,
    },
    {
      question: "What does Premium Support include?",
      answer: `Premium Support is available for our Premium package, offering priority assistance with a response time of just 10 minutes during business hours. We focus on providing enhanced support to clients who choose this option.`,
    },
  ];

  return (
    <>
      <section id="faq">
        <h2 style={{ paddingLeft: "10px" }}>Common Questions</h2>
        <p style={{ paddingLeft: "12px" }}>Got questions? We’re here to assist you!</p>
        <div className="accordion">
          {faqData.map((faq, index) => (
            <div className="faq-item" key={index}>
              <button
                className={`home-accordion-button ${activeIndex === index ? "active" : ""}`}
                onClick={() => toggleFAQ(index)}
              >
                <span className="home-plus">{activeIndex === index ? "-" : "+"}</span>{" "}
                {faq.question}
              </button>
              <div
                className="home-accordion-content"
                style={{ display: activeIndex === index ? "block" : "none" }}
              >
                <p>{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="cta-bottom">
        <h2>Get 2 Months Free Trial</h2>
        <p>Start using SalonBoost now and experience our premium features free for 2 months!</p>
        <Link to="/register" className="cta-button">
          Claim Your Free Trial
        </Link>
      </section>

      <footer>
        <p>&copy; {new Date().getFullYear()} SalonBoost. All rights reserved.</p>
      </footer>
    </>
  );
};

export default Footer;
