import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { useParams } from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
};

const FeedbackPage = () => {
  const params = useParams();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [salonData, setSalonData] = useState({});
  const [comment, setComment] = useState("");
  const stars = Array(5).fill(0);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const response = await fetch(`${baseURL}/feedback/${params.id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const { data } = await response.json();
          setSalonData(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (params.id) {
      fetchDataAsync();
    }
  }, [params.id]);

  const handleClick = (value) => {
    setCurrentValue(value);
    if (value > 3) {
      const googleReviewLink = salonData.salonFeedback;
      window.open(googleReviewLink, "_blank");
      setCurrentValue(0);
    } else {
      return;
    }
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const handleSubmit = async () => {
    const feedbackData = {
      rating: currentValue,
      salonId: params.id.split("-")[0],
      customerId: params.id.split("-")[1],
      comment,
    };
    try {
      const response = await fetch(`${baseURL}/feedback/feedbackCreate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(feedbackData),
      });
      console.log(response);
      if (response.ok) {
        const { data, message } = await response.json();
        console.log(data);
        setIsSubmitted(true);
        alert(message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="wrapper min-vh-100" style={styles.wrapper}>
        {isSubmitted ? (
          <h1 style={styles.container}>Thank you for your feedback!</h1>
        ) : (
          <>
            <div style={styles.container}>
              <h2> Salonboost Ratings </h2>
              <div style={styles.stars}>
                {stars.map((_, index) => {
                  return (
                    <FaStar
                      key={index}
                      size={24}
                      onClick={() => handleClick(index + 1)}
                      onMouseOver={() => handleMouseOver(index + 1)}
                      onMouseLeave={handleMouseLeave}
                      color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
                      style={{
                        marginRight: 10,
                        cursor: "pointer",
                      }}
                    />
                  );
                })}
              </div>
              <textarea
                placeholder="What's your experience?"
                style={styles.textarea}
                name="comment"
                onChange={(e) => setComment(e.target.value)}
              />

              <button style={styles.button} onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2F6FF",
  },
  container: {
    paddingTop: 100,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  stars: {
    display: "flex",
    gap: 15,
    flexDirection: "row",
  },
  textarea: {
    border: "1px solid #5a27e5",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: 500,
    minWidth: 300,
  },
  button: {
    border: "1px solid #5a27e5",
    borderRadius: 5,
    width: 500,
    minwidth: 300,
    padding: 10,
  },
};

export default FeedbackPage;
