import React, { useEffect, useRef, useState } from 'react'

const Testimonial = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const trackRef = useRef(null);
  const slidesRef = useRef([]);

  const slideWidth = slidesRef.current[0]?.getBoundingClientRect().width || 0;

  useEffect(() => {
    // Set slide position for each slide
    slidesRef.current.forEach((slide, index) => {
      slide.style.left = slideWidth * index + "px";
    });
  }, [slideWidth]);

  const moveToSlide = (targetSlide) => {
    if (trackRef.current) {
      trackRef.current.style.transform = "translateX(-" + targetSlide.style.left + ")";
    }
  };

  const handleNextClick = () => {
    if (currentSlide < slidesRef.current.length - 4) {
      // Show 4 slides at a time
      setCurrentSlide((prevSlide) => {
        const newSlide = prevSlide + 1;
        moveToSlide(slidesRef.current[newSlide]);
        return newSlide;
      });
    }
  };

  const handlePrevClick = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevSlide) => {
        const newSlide = prevSlide - 1;
        moveToSlide(slidesRef.current[newSlide]);
        return newSlide;
      });
    }
  };

  return (
    <>
      <div id="testimonial" className="testimonial-section">
        <h2>Listen to What Customers Love About SalonBoost!</h2>
        <div className="testimonial-slider">
          <div className="testimonial-track" ref={trackRef}>
            {/* Testimonial Videos using YouTube in 9:16 */}
            {[
              "Ip2LjvWc-yA",
              "Ip2LjvWc-yA",
              "VIDEO_ID_3",
              "VIDEO_ID_4",
              "VIDEO_ID_5",
              "VIDEO_ID_6",
            ].map((videoId, index) => (
              <div
                className="testimonial-slide"
                key={index}
                ref={(el) => (slidesRef.current[index] = el)}
              >
                <div className="video-container">
                  <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${videoId}`}
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Slider Controls */}
        <button className="prev-slide" onClick={handlePrevClick}>
          ❮
        </button>
        <button className="next-slide" onClick={handleNextClick}>
          ❯
        </button>
      </div>

      <section className="industries-section">
        <h2>Where Can SalonBoost Be Used?</h2>
        <div className="industries-grid">
          {/* <!-- Salon Software --> */}
          <div className="industry-tile">
            <img src="images/salon.jpg" alt="Salon Software" />
            <p>Salon Software</p>
          </div>
          {/* <!-- Spa Software --> */}
          <div className="industry-tile">
            <img src="images/spa.jpg" alt="Spa Software" />
            <p>Spa Software</p>
          </div>
          {/* <!-- Hair Salon Software --> */}
          <div className="industry-tile">
            <img src="images/hair-salon.jpg" alt="Hair Salon Software" />
            <p>Hair Salon Software</p>
          </div>
          {/* <!-- Aesthetic Clinic Software --> */}
          <div className="industry-tile">
            <img src="images/aesthetic-clinic.jpg" alt="Aesthetic Clinic Software" />
            <p>Aesthetic Clinic Software</p>
          </div>
          {/* <!-- Nail Salon Software --> */}
          <div className="industry-tile">
            <img src="images/nail-salon.jpg" alt="Nail Salon Software" />
            <p>Nail Salon Software</p>
          </div>
          {/* <!-- Med Spa Software --> */}
          <div className="industry-tile">
            <img src="images/med-spa.jpg" alt="Med Spa Software" />
            <p>Med Spa Software</p>
          </div>
          {/* <!-- Massage Therapy Software --> */}
          <div className="industry-tile">
            <img src="images/massage-therapy.jpg" alt="Massage Therapy Software" />
            <p>Massage Therapy Software</p>
          </div>
          {/* <!-- Tanning Salon Software --> */}
          <div className="industry-tile">
            <img src="images/tanning-salon.jpg" alt="Tanning Salon Software" />
            <p>Tanning Salon Software</p>
          </div>
          {/* <!-- Bridal Salon Software --> */}
          <div className="industry-tile">
            <img src="images/bridal-salon.jpg" alt="Bridal Salon Software" />
            <p>Bridal Salon Software</p>
          </div>
          {/* <!-- Beauty Salon Software --> */}
          <div className="industry-tile">
            <img src="images/beauty-salon.jpg" alt="Beauty Salon Software" />
            <p>Beauty Salon Software</p>
          </div>
          {/* <!-- Barbershop Software --> */}
          <div className="industry-tile">
            <img src="images/barbershop.jpg" alt="Barbershop Software" />
            <p>Barbershop Software</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonial